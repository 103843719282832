import { request } from '@/libs/index';

function signupRequest({
  existsInOkta = false,
  email = '',
  firstName = '',
  lastName = '',
  organization = '',
  password = '',
  country = 'US',
  account_type = 'both',
  jobPosition = '',
  token = '',
}) {
  return request({
    method: 'POST',
    url: '/register',
    data: {
      existsInOkta,
      username: email,
      firstName,
      lastName,
      account_name: organization,
      password: password,
      country: country,
      account_type, // 'publisher', 'advertiser' or 'both'
      jobPosition,
      token,
    },
  });
}

export { signupRequest as default };

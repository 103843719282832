import PropTypes from 'prop-types';

function FormLabel(props) {
  return (
    <label htmlFor={props.htmlFor || ''} className="block pl-8">
      <span className="inline-block font-Roboto font-medium text-neutral-700 text-small h-12 leading-regular">
        {props.text || ''}
      </span>
      {props.children}
    </label>
  );
}

FormLabel.propTypes = {
  children: PropTypes.element,
  text: PropTypes.string,
  htmlFor: PropTypes.string,
};

export default FormLabel;

import axios from 'axios';
import { REQUEST_HEADERS, API_ENDPOINT } from '../configs';

function handleErrResponse(err) {
  // Errors SHOULD follow the backend API errors' definition to keep consistency.
  // An Example: { code: Number, message: [String,...]}
  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return Promise.reject(err.response.data);
  } else if (err.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser
    return Promise.reject({
      code: '400',
      message: [
        'No response was received. Please make sure the request is valid.',
      ],
    });
  } else {
    // Something happened in setting up the request that triggered an Error
    return Promise.reject({
      code: '400',
      message: [err.message],
    });
  }
}

function request({
  method = 'GET',
  baseURL = API_ENDPOINT,
  url = '/',
  baseHeaders = REQUEST_HEADERS,
  headers = {},
  timeout = 10000, // in milliseconds
  responseType = 'json',
  responseEncoding = 'utf8',
  data,
  params,
}) {
  const targetHeaders = Object.assign({}, baseHeaders, headers);

  return axios({
    method,
    url,
    baseURL,
    headers: targetHeaders,
    timeout,
    data,
    params,
    responseType,
    responseEncoding,
  }).catch((e) => {
    return handleErrResponse(e);
  });
}

export { request as default };

import PropTypes from 'prop-types';

function Card({ children, dataTestId, ...props }) {
  return (
    <section
      className="w-[466px] block bg-neutral-0 border border-neutral-300 p-32 mb-[72px] rounded"
      data-testid={dataTestId}
      {...props}
    >
      {children}
    </section>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

Card.defaultProps = {
  dataTestId: 'components-card',
};

export default Card;
